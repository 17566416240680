<template>
  <footer
    class="footer__container"
    :style="{
      backgroundColor: colorUi.backgroundColor,
      color: colorUi.footerTextColor,
      zIndex: 200,
      width: '100%'
    }"
  >
    <v-row
      class="justify-space-around justify-center align-center ma-0 pa-0 d-none d-sm-flex"
    >
      <v-col
        class="col-2 footer__container_img d-flex justify-center align-center"
      >
        <img width="70%" :src="colorUi.firstFooterLogo" alt="" />
      </v-col>

      <v-col
        class="col-2 footer__container_img d-flex justify-center align-center"
      >
        <img width="100%" :src="colorUi.footerLogo" alt="" />
      </v-col>

      <v-col class="col-2 ml-3 d-flex justify-center align-center">
        <div class="d-flex flex-column">
          <p class="mb-2 mb-3 d-flex footer__container_title-item pa-0 ma-0">
            <strong>
              REGIONAL MINAS GERAIS
            </strong>
          </p>

          <p class="d-flex pa-0 ma-0 footer__contacts_items" :style="{ 'color': colorUi.footerTextColor }">
            <v-icon class="mr-1" :color="colorUi.footerTextColor" small
              >mdi-web</v-icon
            >
            <a href="http://mg.caritas.org.br/" target="_blank" :style="{ 'color': colorUi.footerTextColor }">
            mg.caritas.org.br
            </a>            
          </p>

          <p class="d-flex pa-0 ma-0 footer__contacts_items" :style="{ 'color': colorUi.footerTextColor, 'text-decoration': 'none' }">
            <v-icon class="mr-1" :color="colorUi.footerTextColor" small
              >mdi-email-outline</v-icon
            >
            <a href="mailto:caritasmg@caritas.org.br" :style="{ 'color': colorUi.footerTextColor, 'text-decoration': 'none' }">caritasmg@caritas.org.br</a>
          </p>
        </div>
      </v-col>

      <v-col class="col-2 d-flex flex-column">
        <p class="footer__container_title-item d-flex justify-center">
          <strong>REDES SOCIAIS</strong>
        </p>
        <v-row class="d-flex justify-center">
          
          <a href="https://www.facebook.com/caritasmg" target="_blank">
          <v-icon medium class="ma-2" :color="colorUi.footerTextColor"
            >mdi-facebook</v-icon
          >
          </a>
            
          <a href="https://www.instagram.com/caritasmg/" target="_blank">       
          <v-icon medium class="ma-2" :color="colorUi.footerTextColor"
            >mdi-instagram</v-icon
          >
          </a>
          <a href="https://twitter.com/CaritasMG" target="_blank">
          <v-icon medium class="ma-2" :color="colorUi.footerTextColor"
            >mdi-twitter</v-icon
          >
          </a>
          <a href="https://www.youtube.com/channel/UCKemiHoXrNTpsepVU-2jvJQ" target="_blank">
          <v-icon medium class="ma-2" :color="colorUi.footerTextColor"
            >mdi-youtube</v-icon
          >
          </a>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      class="d-flex justify-center flex-column align-center ma-0 pa-0 d-sm-none"
      :style="{
        backgroundColor: colorUi.backgroundColor,
        color: colorUi.footerTextColor,
      }"
    >
      <v-col class="footer__container_img d-flex justify-center align-center">
        <img width="30%" :src="colorUi.firstFooterLogo" alt="" />
      </v-col>

      <v-col class="footer__container_img d-flex justify-center align-center">
        <img width="40%" :src="colorUi.footerLogo" alt="" />
      </v-col>

      <v-col class="ml-3 d-flex justify-center align-center">
        <div class="d-flex flex-column">
          <p class="mb-2 mb-3 d-flex footer__container_title-item pa-0 ma-0">
            <strong>
              REGIONAL MINAS GERAIS
            </strong>
          </p>

          <p class="d-flex pa-0 ma-0 footer__contacts_items">
            <v-icon class="mr-1" :color="colorUi.footerTextColor" small
              >mdi-web</v-icon
            >
            www.mg.caritas.org.br
          </p>

          <p class="d-flex pa-0 ma-0 footer__contacts_items">
            <v-icon class="mr-1" :color="colorUi.footerTextColor" small
              >mdi-email-outline</v-icon
            >
            caritasmg@caritas.org.br
          </p>
        </div>
      </v-col>

      <v-col class="d-flex flex-column">
        <p class="footer__container_title-item d-flex justify-center">
          <strong>REDES SOCIAIS</strong>
        </p>
        <v-row class="d-flex justify-center">
          <v-icon small class="ma-2" :color="colorUi.footerTextColor"
            >mdi-facebook</v-icon
          >
          <v-icon small class="ma-2" :color="colorUi.footerTextColor"
            >mdi-instagram</v-icon
          >
          <v-icon small class="ma-2" :color="colorUi.footerTextColor"
            >mdi-twitter</v-icon
          >
          <v-icon small class="ma-2" :color="colorUi.footerTextColor"
            >mdi-youtube</v-icon
          >
        </v-row>
      </v-col>
    </v-row>
  </footer>
</template>

<script>
export default {
  computed: {
    colorUi() {
      return this.$route.meta.style;
    },
  },
};
</script>

<style scoped>

a {
  text-decoration: none;
  color: inherit;
}


</style>
