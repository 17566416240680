var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('nav',{staticClass:"header__container d-none d-sm-flex",style:({
      'background-color': _vm.colorUi.backgroundColor,
      color: _vm.colorUi.footerTextColor,
      zIndex: 200,
    })},[_c('img',{staticStyle:{"padding":"1em"},attrs:{"max-width":"2%","src":_vm.colorUi.logoSrc,"alt":""}}),_vm._l((_vm.headerItems),function(item,idx){return _c('router-link',{key:idx,attrs:{"tag":"li","to":{ name: item.routeName }}},[_vm._v(" "+_vm._s(item.label)+" ")])})],2),_c('nav',{staticClass:"mobile__header d-flex justify-center align-center d-sm-none",style:({
      'background-color': _vm.colorUi.backgroundColor,
      color: _vm.colorUi.footerTextColor,
    })},[_c('v-row',{staticClass:"d-flex justify-space-between align-center mobile_navbar_container"},[_c('v-app-bar-nav-icon',{staticClass:"ml-5",attrs:{"cols":"1","color":"#FFF"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('h1',[_vm._v("Mariana Território Atingido")]),_c('v-img',{staticClass:"mr-2",attrs:{"max-width":"10%","src":_vm.colorUi.logoSrc,"alt":""}})],1),(_vm.drawer)?_c('v-navigation-drawer',{attrs:{"color":_vm.colorUi.backgroundColor,"height":"100%","width":"100%","absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"vh100"},[_c('div',{staticClass:"d-flex align-center justify-end mr-3 mt-3",on:{"click":_vm.closeDrawer}},[_c('v-icon',{attrs:{"color":_vm.colorUi.footerTextColor}},[_vm._v("mdi-close")])],1),_c('div',{staticClass:"mobile__links_nav d-flex flex-column justify-center align-center"},_vm._l((_vm.headerItems),function(item,idx){return _c('router-link',{key:idx,attrs:{"tag":"p","to":{ name: item.routeName }}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }