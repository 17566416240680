<template>
  <header>
    <nav
      class="header__container d-none d-sm-flex"
      :style="{
        'background-color': colorUi.backgroundColor,
        color: colorUi.footerTextColor,
        zIndex: 200,
      }"
    >
      <img style="padding: 1em;" max-width="2%" :src="colorUi.logoSrc" alt="" />

      <router-link
        tag="li"
        v-for="(item, idx) in headerItems"
        :key="idx"
        :to="{ name: item.routeName }"
      >
        {{ item.label }}
      </router-link>
    </nav>

    <nav
      class="mobile__header d-flex justify-center align-center d-sm-none"
      :style="{
        'background-color': colorUi.backgroundColor,
        color: colorUi.footerTextColor,
      }"
    >
      <v-row
        class="d-flex justify-space-between align-center mobile_navbar_container"
      >
        <v-app-bar-nav-icon
          class="ml-5"
          cols="1"
          color="#FFF"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <h1>Mariana Território Atingido</h1>

        <v-img class="mr-2" max-width="10%" :src="colorUi.logoSrc" alt="" />
      </v-row>

      <v-navigation-drawer
        :color="colorUi.backgroundColor"
        height="100%"
        width="100%"
        v-model="drawer"
        v-if="drawer"
        absolute
        temporary
      >
        <div class="vh100">
          <div
            @click="closeDrawer"
            class="d-flex align-center justify-end mr-3 mt-3"
          >
            <v-icon :color="colorUi.footerTextColor">mdi-close</v-icon>
          </div>
          <div
            class="mobile__links_nav d-flex flex-column justify-center align-center"
          >
            <router-link
              tag="p"
              v-for="(item, idx) in headerItems"
              :key="idx"
              :to="{ name: item.routeName }"
            >
              {{ item.label }}
            </router-link>
          </div>
        </div>
      </v-navigation-drawer>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      headerItems: [
        { label: "Início", routeName: "Home" },
        { label: "Quem somos", routeName: "cards" },
        { label: "O Projeto", routeName: "projeto" },
        { label: "O Território", routeName: "mapa_territorios" },
        { label: "Conteúdos Temáticos", routeName: "conteudos_tematicos" },
        { label: "Acervos de Publicações", routeName: "acervo_publicacoes" },
      ],

      drawer: false,
    };
  },

  methods: {
    closeDrawer() {
      this.drawer = false;
    },
  },

  computed: {
    colorUi() {
      return this.$route.meta.style;
    },
  },
};
</script>

<style scoped>
nav li {
  list-style-type: none;
  font-size: 0.6em;
  font-weight: bold;
}

nav li:hover,
nav li.router-link-active,
nav li.router-link-exact-active {
  font-weight: bold;
  cursor: pointer;
}

.vh100 {
  height: 100vh;
}

.header__container {
  position: relative;
  z-index: 5;
  padding: 1em;

  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 10vh;
  width: 100vw;
}

.header__container img {
  padding: 0;
  width: 4em;
  height: 4em;
}

.header__container li {
  cursor: pointer;
}

.mobile__header {
  width: 100%;
  padding: 1em;
}
.mobile_navbar_container img {
  width: 100%;
}

.mobile_navbar_container h1 {
  font-size: 1em;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.mobile__links_nav {
  height: 100%;
}

.mobile__links_nav p {
  cursor: pointer;
}

header {
  z-index: 100;
}
</style>
