<template>
  <div id="app">
    <v-app>
      <Header class="zindex10" />
      <v-main class="pa-0 ma-0">
        <transition>
          <router-view />
        </transition>
      </v-main>
      <v-footer v-if="this.$route.name != 'mapa_territorios'" padless>
        <Footer v-if="this.$route.name != 'Home'" />
      </v-footer>
      
    </v-app>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>

</style>
